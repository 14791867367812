<template>

      <div>
		    <img  v-if="status==0 " src="../../assets/img/kafei.jpg" class="noMemberBg" />
			<div>
				 如吻生活一年一度的周年庆将于10.18号开始！现在征集大家的周年庆口号！
			</div>
			<el-form ref="form" :model="form"  id="selectForm">
				  <el-form-item>
					 <!--
				  	 <div class="formItem"><div class="icon">*</div>顾客地址:</div> 
					 -->
				    <el-input  type="textarea" rows="8" v-model="form.cust_address" placeholder="请把你的奇思妙想写上去吧"></el-input>
				  </el-form-item>


				<template>
				   <div>提交图片</div>
				   <div style="text-align:left;width: 335px; margin: auto;">
					<el-upload :headers="headers" action="https://rwmember.rwkissy.cn/api/upload"  list-type="picture-card" :on-remove="handleRemove" accept=".jpg,.jpeg,.png,.gif"
					 :on-success="handlePic" multiple :file-list="listPic">
						<i class="el-icon-plus" />
					</el-upload>
					<el-dialog :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
				   </div>
				</template>



				   <el-button type="primary"  @click="addList">提交</el-button>
			</el-form>	
	  </div>
	  
</template>

<script>
    import { getUser, modelTrainDel,addMessage } from '@/api/modelTrain/user'
    export default {
        data() {
            return {
                list: [],
				status:2,
				id:'',
				form:{
					username: '',
					m_id: '',
					cust_name: '',
					cust_id: '',
					cust_phone: '',
					cust_address: '',
					credent:'',
				},
				pictureLists:[], //上传的图片
				listPic: [], //显示的图片
            }
        },
        watch:{

        },
        computed: {

        },
        created() {
          
        },
		mounted() {
			 /*
			 let token=window.sessionStorage.getItem('token')
			 if(token!=null){
			 	this.$router.push({path: '/form'})
				return
			 }
			 
			 */
			 let code = this.getUrlParam("code"); //是否存在code
			 if (code == null || code === "" ) {
			 	 this.getCode()
			 }else{
				this.getToken(code)
			 }

				
		},
        methods: {
			
				// 上传图片
				handlePic(response, file, fileList) {
					if (response.errno === 0) {
						this.dialogImageUrl = response.data.url
						this.pictureLists.push(response.data.url)
						console.log('pictureLists',this.pictureLists)
					}
					this.pictureLists.push(response.data.url)
					console.log('pictureLists',this.pictureLists)
				},
				//删除
				handleRemove(file, fileList) {
					const url = file.response.data.url
					const arr = []
					this.pictureLists.forEach((element) => {
						if (element !== url) {
							arr.push(element)
						}
					})
					this.pictureLists = arr
				},
			   addList(){
				    let data={}
					data.id=this.id,
					data.text=this.form.cust_address
					data.pictureLists=this.pictureLists.join(",");
				    addMessage(data).then(res=>{
					    alert('已提交')
				   })
				   
			   },
			  //授权
			  getCode(){
				   //return
				  // snsapi_base (静默授权)和snsapi_userinfo（非静默授权）
				 // window.location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf8a3a83718a008d4&redirect_uri=https://rwadmin.xinzhibang168.com/test/dist&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
			        window.location.href="https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf8a3a83718a008d4&redirect_uri=http://spaceh5.rwkissy.cn/&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
			  },
			  getUrlParam(name){
			  				  		let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
			  				  		let r = window.location.search.substr(1).match(reg);
			  				  		if (r != null) {
			  				  			return unescape(r[2]);
			  				  		}
			  				  		return null;
			  },
			  //code登录
			  getToken(code){
				  
					  let data={}
					  data.code=code
					  data.script='snsapi_base'
					  getUser(data).then(res =>{
						     this.id=res.data.bussData.id
						    /*
						   if(res.code==0){
						   	  this.getCode()
							  return 
						   }
						    this.status=res.data.status
						   if(this.status==1){
							   window.sessionStorage.setItem('token', res.data.token)
							   this.$router.push({path: '/form'})
						   }
						   */
					  })
					  
			  },
        }
    }
</script>

<style scoped lang="scss">
			.noMemberBg{
				    object-fit: contain;
				    width: 100%;
					display: block;
			}
			#selectForm >>> .el-textarea{
				       width: 335px;
					   margin-top: 100px;
					   padding: 0 20px;
			}
			#selectForm >>> .el-button--primary{
					background: #000;
					color: #fff;
					border: none;
					width: 150px;
					height: 50px;
					font-size: 16px;
					margin-top: 50px;
			}
			/deep/ .el-upload-list--picture-card .el-upload-list__item{
			                    width: 100px;
			                    height: 100px;
			                    line-height:100px;
			}
			 /deep/ .el-upload--picture-card{
			                    width: 100px;
			                    height: 100px;
								line-height:100px;
			                }
</style>